.Video {
  .react-player__preview {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    .react-player__shadow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  video:focus {
    outline: none;
  }
}
