@import "../../styles/customSettings";
@import "../../styles/mixins";
.HeroTeaser {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-height: 300px;
  height: 720px;
  @include responsive-margin-bottom(rem-calc(80px));
  @include breakpoint(large down) {
      height: 70vh;
      background-position: 80% 50%;
  }
  @include breakpoint(small down) {
    background-position: 70% 50%;
  }
  .hero-teaser-inner {
    background-image: linear-gradient(to right, rgba($black, 0.5) 0%, rgba($black, 0.4) 33%, rgba($black, 0.0) 73%, rgba($black, 0) 100%);
    position: relative;
    height: 100%;
    width: 100%;
    @include breakpoint(small down) {
      background-image: linear-gradient(to right, rgba($black, 0.5) 0%, rgba($black, 0.4) 33%, rgba($black, 0.0) 100%, rgba($black, 0) 100%);
    }
    .hero-teaser-content {
      position: relative;
      top: 20%;
      @at-root .ShoppingCartScreen .HeroTeaser .hero-teaser-inner .hero-teaser-content {
        top: 25.8%;
      }
      @include breakpoint(medium down) {
        top: 3%;
        @at-root .ShoppingCartScreen .HeroTeaser .hero-teaser-inner .hero-teaser-content {
          top: 5%;
        }
      }
      h1 {
        margin-bottom: 14%;
        @include breakpoint(large down) {
          margin-bottom: 10vh;
        }
        @include breakpoint(medium down) {
          margin-bottom: 17vh;
          @at-root .ShoppingCartScreen .HeroTeaser .hero-teaser-inner h1 {
            margin-bottom: 38vh;
          }
        }
        @media screen and (max-width: 359px) {
          margin-bottom: 5vh;
          @at-root .ShoppingCartScreen .HeroTeaser .hero-teaser-inner h1 {
            margin-bottom: 27vh;
          }
        }
      }
      img {
        max-width: rem-calc(376px);
        @include breakpoint(small down) {
          max-width: rem-calc(253px);
        }
      }
    }
  }
}
