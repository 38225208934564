@import "../../styles/customSettings";
.Lightbox {
  .popupbox-wrapper {
    position: relative;
    width: 100%;
    max-width: rem-calc(1400px);
    padding: rem-calc(80px) 0;
    @include breakpoint(xlarge down) {
      max-width: calc(100% - #{$content-padding*2});
    }
    @include breakpoint(medium down) {
      padding: rem-calc(40px) 0;
    }
  }
  .popupbox-content {
    .terms-and-conditions,
    .imprint,
    .privacy-policy,
    .campaign-has-ended {
      overflow-y: scroll;
      height: 100%;
      max-height: 62vh;
      padding: 0 rem-calc(20px);
    }
    padding: 20px 0 20px;
    @include breakpoint(medium down) {
      text-align: center;
    }
  }
  .cart-container {
    padding-top: rem-calc(32px);
    text-align: right;
    @include breakpoint(medium down) {
      text-align: center;
    }
    @include breakpoint(xsmall down) {
      padding-top: rem-calc(16px);
    }
    button {
      @include breakpoint(large up) {
        padding-left: $content-padding*4;
        padding-right: $content-padding*4;
      }
    }
  }
  .product-image {
    @include breakpoint(medium down) {
      margin-bottom: rem-calc($content-padding);
    }
    img {
      max-width: 354px;
      display: block;
      margin: 0 auto;
      width: 100%;
      @include breakpoint(small down) {
        max-width: 220px;
      }
      @include breakpoint(xsmall down) {
        max-width: 180px;
      }
    }
  }
  .lb-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    padding-left: 26px;
    &:before,
    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 0;
      border: 1px solid $default-font-color;
      top: 50%;
      left: 0;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
