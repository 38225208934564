@import "../../styles/customSettings";
.layout-page-footer {
  .footer-social {
    background-color: $footer-social-bg-color;
    position: relative;
    min-height: $footer-social-height;
    .footer-social-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      > p {
        margin-bottom: rem-calc(30px);
      }
      .footer-social-icons {
        .icons {
          margin: 0 rem-calc(16px);
        }
      }
    }
  }
  .footer-links {
    padding: rem-calc(80px) 0;
    background-color: $footer-links-bg-color;
    border-bottom: 1px solid $white;
    min-height: $footer-links-height;
    @include breakpoint(medium down) {
      padding-bottom: rem-calc(50px);
    }
    li {
      line-height: 1.88;
      @include breakpoint(medium down) {
        text-align: center;
        &:last-of-type {
          margin-bottom: rem-calc(24px);
        }
      }
      a, a:visited, a:focus, a:active {
        color: $lighter-gray;
      }
      a:hover {
        color: $white;
      }
    }
  }
  .footer-legal {
    padding: rem-calc(35px) 0;
    background-color: $footer-links-bg-color;
    min-height: $footer-legal-height;
    .flex-wrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      height: 100%;
      position: relative;
      img {
        right: 24%;
        position: absolute;
        top: 50%;
        width: 70px;
        transform: translate(50%, -50%);
        @include breakpoint(large down) {
          margin-top: rem-calc(20px);
          position: static;
          transform: none;
        }
      }
    }
    .footer-copyright {
      color: $lighter-gray;
      line-height: 1.88;
      //@include breakpoint(xlarge up) {
      //  &:after {
      //    content: '/';
      //    padding: 0 5px;
      //    color: $lighter-gray;
      //  }
      //}
    }
    .footer-meta {
      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include breakpoint(large down) {
          flex-direction: column;
        }
        li {
          line-height: 1.88;
          a, a:visited, a:focus, a:active {
            color: $lighter-gray;
          }
          a:hover {
            color: $white;
          }
          @include breakpoint(xlarge up) {
            &:not(:last-of-type) {
              &:after {
                content: '/';
                padding: 0 5px;
                color: $lighter-gray;
              }
            }
          }
        }
      }
    }
  }
  .footer-nav-category {
    margin-bottom: rem-calc(24px);
    color: $lighter-gray;
    line-height: 1.33;
    @include breakpoint(medium down) {
      text-align: center;
    }
  }
  .footer-logo {
    @include breakpoint(xlarge up) {
      margin-right: rem-calc(35px);
    }
  }


  //width: 100%;
  //color: $footer-font-color;

  //@include breakpoint(small down) {
  //  height: auto;
  //}
  //
  //.layout-page-footer-inner {
  //  height: 100%;
  //  margin: 0 auto;
  //  display: flex;
  //  align-items: center;
  //  max-width: $screen-width;
  //  padding: 0 $content-padding;
  //  > a {
  //    display: block;
  //    width: 33.33333%;
  //    text-align: center;
  //    color: $footer-font-color;
  //  }
  //  @include breakpoint(small down) {
  //    display: block;
  //    > a {
  //      width: 100%;
  //      padding: $content-padding/2 0;
  //    }
  //  }
  //}
  //.copyright {
  //  position: absolute;
  //  font-size: $copyright-font-size;
  //  bottom: 0;
  //  left: 50%;
  //  transform: translateX(-50%);
  //}

  //.footer a {
  //  text-decoration: none;
  //  color: inherit;
  //}
  //.footer .copy-1 {
  //  color: #ccc;
  //}
  //.footer__social {
  //  color: #666;
  //  background-color: #e5e5e5;
  //}
  //.footer__social-icons a:active,
  //.footer__social-icons a:hover {
  //  color: #000;
  //}
  //.footer__social-icons a:not(:last-child) {
  //  margin-right: 10px;
  //}
  //.footer__links {
  //  color: #ccc;
  //  background: #666;
  //}
  //.footer__links .title-2 {
  //  color: #ccc;
  //}
  //.footer__links li {
  //  margin: 10px 0;
  //}
  //.footer__links a:hover {
  //  color: #fff;
  //}
  //.footer__legal {
  //  background: #666;
  //  text-align: center;
  //  border-top: 1px solid #999;
  //}
  //.footer__linklist a {
  //  margin-top: 14px;
  //  padding: 0 15px;
  //  display: inline-block;
  //}
  //@media (max-width: 33.74em) {
  //  .footer__linklist a {
  //    display: block;
  //  }
  //  .footer__linklist a:not(:last-child) {
  //    margin-bottom: 10px;
  //  }
  //}
  //.footer__linklist a:hover {
  //  color: #fff;
  //}
  //.footer__football {
  //  margin: 30px 0;
  //}
  //@media (min-width: 64em) {
  //  .footer__football {
  //    float: right;
  //    margin-bottom: -100px;
  //  }
  //}
  //.footer__football a,
  //.footer__football img {
  //  display: inline-block;
  //}
  //.footer__copyright {
  //  clear: both;
  //}
  //.footer__logo {
  //  margin-left: auto;
  //  margin-right: auto;
  //}



}
