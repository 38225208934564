@import "../styles/customSettings";
@import "../styles/mixins";
body {
  &.campaign-has-ended-body {
    overflow: hidden;
    .layout-page-head,
    .layout-page-body {
      filter: blur(10px);
    }
  }
  .layout-page-head.lightbox {
    filter: none;
    position: sticky;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    width: calc(100% + 2.5rem);
    .layout-page-head-inner > a.brand-logo {
      left: 24px;
    }
  }
  
  .Lightbox {
    .popupbox-wrapper {
      max-width: rem-calc(787px);
      padding-top: 0;
      .popupbox-content {
        padding: 0;
        .campaign-has-ended {
          overflow-y: visible;
          &.scroll {
            overflow-y: scroll;
          }
          > .grid-container {
            padding: 80px 90px 20px;
            @include breakpoint(medium down) {
              padding: 40px 0 20px;
            }
            .lb-close {
              right: 30px;
              z-index: 10;
            }
            h2 {
              @include responsive-font-size($default-font-size*2.375, rem-calc(20px));
              @include breakpoint(medium down) {
                word-break: break-word;
              }
            }
            .h4 {
              margin-bottom: rem-calc(40px);
            }
            .button {
              margin-bottom: rem-calc(20px);
              width: 100%;
              max-width: 335px;
              + div a {
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}
