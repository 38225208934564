@import "../../styles/customSettings";
@import "../../styles/mixins";
@import "../../styles/typo";

#dropin {
  margin: 0 auto;
  position: relative;
  padding-bottom: rem-calc(80px);
  .adyen-checkout__payment-method--selected {
    background-color: transparent;
    border: none;
    margin: 0;
    padding-top: rem-calc(48px);
    .adyen-checkout__payment-method__name {
      @extend strong;
    }
    .adyen-checkout__payment-method__header {
      display: none;
      .adyen-checkout__payment-method__header__title {
        visibility: hidden;
      }
      margin-bottom: rem-calc(13px);
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      @include responsive-padding-top(rem-calc(48px));
    }
    .adyen-checkout__payment-method__details {
      padding-left: 0;
      padding-right: 0;
    }
    .adyen-checkout__payment-method__brands {
      img {
        width: 100%;
        height: auto;
      }
      .adyen-checkout__payment-method__image__wrapper {
        height: auto;
        width: 44px;
        &:after {
          display: none;
        }
      }
    }
  }
  .adyen-checkout__card__form {
    overflow: hidden;
    .adyen-checkout__helper-text, .adyen-checkout__label__text {
      @extend strong;
      margin-bottom: rem-calc(10px);
    }
    .adyen-checkout__field {
      @include responsive-margin-bottom(rem-calc(22px));
    }
    .adyen-checkout__input {
      border: $input-border;
      border-radius: 4px;
      background-color: $white;
      height: rem-calc(40px);
    }
    .adyen-checkout__field--cardNumber {
      float: left;
      width: calc(62.5% - 10px);
      @include breakpoint(small down) {
        width: 100%;
      }
      ._1rgeOw_0KD58dhkU_NSVLF {
        width: auto;
        height: 20px;
      }
    }
    .adyen-checkout__card__exp-cvc {
      float: right;
      width: calc(37.5% - 10px);
      .adyen-checkout__field--50:first-child {
        margin-right: 0;
      }
      @include breakpoint(small down) {
        width: 100%;
        .adyen-checkout__field {
          margin-right: 0 !important;
        }
      }
      .adyen-checkout__field {
        width: 100% !important;
      }
    }
  }
  .adyen-checkout__button.adyen-checkout__button--pay {
    margin-top: 0;
    bottom: rem-calc(48px);
    @include breakpoint(small down) {
      bottom: rem-calc(48*$mobile-factor);
    }
    position: absolute;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    .adyen-checkout__button__text {
      text-transform: uppercase;
    }
  }
  .adyen-checkout__store-details {
    display: none;
  }
  .adyen-checkout__threeds2__challenge.adyen-checkout__threeds2__challenge--05 {
    padding-top: 30%;
    margin-top: rem-calc(48px);
    @media screen and (max-width: 950px) {
      padding-top: 80%;
    }
    @media screen and (max-width: 480px) {
      padding-top: 100%;
    }
    @include breakpoint(small down) {
      margin-top: rem-calc(48px*#{$mobile-factor});
    }
  }
}


  h1 {
    margin-bottom: 25px;
  }
  .text-content {
    margin-bottom: 25px;
  }
  .adyen-checkout__threeds2__challenge.adyen-checkout__threeds2__challenge--05 {
    padding-top: 30%;
    @media screen and (max-width: 950px) {
      padding-top: 80%;
    }
    @media screen and (max-width: 480px) {
      padding-top: 100%;
    }
  }
  .adyen-checkout__store-details {
    display: none;
  }


  .success-msg,
  .error-msg {
    text-align: center;
    .threeds-auth-not-successfull {
      h2 + p {
        @include responsive-margin-bottom(rem-calc(64px));
        + img {
          width: rem-calc(100px);
          @include responsive-margin-bottom(rem-calc(16px));
        }
      }
    }
    img {
      width: rem-calc(72px);
      display: block;
      margin: 0 auto;
      @include responsive-margin-bottom(rem-calc(32px));
    }
    .threeds-auth-successfull {
      img {
        @include responsive-margin-bottom(rem-calc(32px));
      }
    }
    .unauthorized-card {
      h2 {
        @include responsive-margin-bottom(rem-calc(32px));
      }
      img {
        width: rem-calc(165px);
      }
    }
    h2 {
      @include responsive-margin-bottom(rem-calc(16px));
    }
    p:last-of-type {
      @include responsive-margin-bottom(rem-calc(48px));
    }
  }


