@import "../styles/customSettings";
@import "../styles/mixins";
.CheckoutPaymentScreen {
  .layout-page-body-inner {
    @include responsive-padding-top(rem-calc(80px));
  }
  .content-container-1 {
    h2 {
      @include responsive-margin-bottom(0);
    }
    @include breakpoint(large up) {
      margin-bottom: rem-calc(80px);
    }
  }
  .threeds-checker-container {
    background-color: $light-gray-2;
  }

  @include two-fa-how-to;
  @include two-fa-how-to-video;
  .two-fa-how-to {
    background-color: $white;
    .large-6 {
      background-color: $light-gray-2;
    }
  }

  &.second-step {

  }

  &.third-step {
    min-height: calc(100vh - #{$header-height} - #{$footer-legal-height});
    .threeds-checker-container{
      background-color: transparent;
    }
    .content-container-1,
    .two-fa-how-to,
    .two-fa-how-to-video {
      display: none;
    }
  }
}
