@import "../../styles/customSettings";
@import "../../styles/mixins";
.product-card {
  @include breakpoint(medium down) {
    //max-width: 460px;
    margin: 0 auto;
    @include responsive-margin-bottom(rem-calc(40px));
  }
  .product-card-inner {
    background-color: $light-gray-2;
    @include responsive-margin-bottom(rem-calc(32px));
    position: relative;
    height: 255px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: auto;
      max-height: 172px;
      image-rendering: optimizeQuality;
      cursor: pointer;
    }
  }
  .product-card-text {
    position: relative;
    @include responsive-margin-bottom(rem-calc(32px));
    h3 {
      margin-bottom: 0;
    }
    a {
      display: block;
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .button {
    width: 100%;
  }
}
