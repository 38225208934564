@import "../../styles/customSettings";
@import "../../styles/mixins";
.ProductsGrid {
  .grid-x {
    flex-direction: row-reverse;
    @include breakpoint(medium down) {
      flex-direction: column-reverse;
    }
  }
  .h1 {
    color: $default-font-color;
    @include responsive-margin-bottom(rem-calc(64px));
  }
}
