@import "../styles/customSettings";
@import "../styles/mixins";
.CheckoutRegisterScreen {
  .layout-page-body-inner {
    @include responsive-padding-top(rem-calc(80px));

    .privacy-policy,
    input[type="email"] {
      @include responsive-margin-bottom(rem-calc(32px));
    }
    .registration-form-text {
      > p:last-of-type {
        @include responsive-margin-bottom(rem-calc(48px));
      }
    }
  }
}
