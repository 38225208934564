@import "theme/settings";
// 2. Breakpoints
// --------------

// Background Images
// home & login
//$HomeScreenBackground: url("../assets/images/HomeScreen-bg.jpg");
//$ConfirmScreenBackground: $HomeScreenBackground;
//$ImprintScreenBackground: $HomeScreenBackground;
//$PrivacyPolicyScreenBackground: $HomeScreenBackground;
//$TermsAndConditionsScreenBackground: $HomeScreenBackground;
//$LoginScreenBackground: $HomeScreenBackground;
//$AlreadyRegisteredScreenBackground: $HomeScreenBackground;
//$UnauthorizedScreenBackground: $HomeScreenBackground;
//$RegistrationExpiredScreenBackground: $HomeScreenBackground;
// three ds checker
//$ThreeDsCheckerScreenBackground: $HomeScreenBackground;
// prize draw
//$PrizeDrawScreenBackground: $HomeScreenBackground;
//$PrizeDrawWonScreenBackground: $HomeScreenBackground;
//$PrizeDrawLostScreenBackground: $HomeScreenBackground;
//$AlreadyParticipatedScreenBackground: $HomeScreenBackground;
// landingpages
//$LandingpageScreenBackground: $HomeScreenBackground;
//$LandingpageBypassScreenBackground: $HomeScreenBackground;
//$CampaignHasEndedScreenBackground: $HomeScreenBackground;
// newsletter
//$NewsletterSubscribedScreenBackground: $HomeScreenBackground;
//$NewsletterConfirmScreenBackground: $HomeScreenBackground;
//$UnsubscribeScreenBackground: $HomeScreenBackground;
//$AlmostSubscribedScreenBackground: $HomeScreenBackground;

// fonts
$fontNormal: "Dosis";
$fontIcons: "ucicons";
$fonts: $fontNormal, $fontIcons;
$default-font-family: $fontNormal,Calibri,Arial,sans-serif;
$headline-font-family: $fontNormal,Calibri,Arial,sans-serif;
$button-font-family: $fontNormal,Calibri,Arial,sans-serif;
$body-font-family: $default-font-family;
$icon-font-family: $fontIcons;

// colors
$foundation-palette: (
  primary: #f99f1d,
  secondary: #e7000f,
  success: #a5cf4d,
  warning: #ffa129,
  alert: #e2001a,
);
@include add-foundation-colors;
$brand-color-1: $primary-color;
$brand-color-2: $secondary-color;
$brand-color-1-darken: darken($primary-color, 5%);
$brand-color-2-darken: darken($secondary-color, 2%);
//$secondary-color-darken: #be8235;
//$secondary-color-lighten: #f2ca96;
$lighter-gray: #cccccc;
$light-gray-1: #e5e5e5;
$light-gray-2: #f5f5f5;
$medium-gray: #666666;
$dark-gray: #333333;
$dark-gray-darken: darken($dark-gray, 2%);
$light-blue: $primary-color;
//$darkblue-lighter: #99b2be;
$black: #000000;
$white: #ffffff;

// global layout vars
$screen-width: $global-width;
$content-padding: rem-calc(20px);

// header
$header-bg-color: $white;
$header-height: rem-calc(80px);
//$logo-height: auto;
//$logo-width: auto;

// footer
$footer-social-bg-color: $light-gray-1;
$footer-links-bg-color: $medium-gray;
$footer-font-color: $white;
$footer-social-height: rem-calc(264px);
$footer-links-height: rem-calc(360px);
$footer-legal-height: rem-calc(130px);
//$footer-height: rem-calc(69px);
//$copyright-font-size: rem-calc(12px);

//// content box
//$content-box-bg-color: rgba($white, 1);
//$content-box-shadow: 0 1px 4px rgba($black, 0.3), 0 0 40px rgba($black, 0.1) inset;

// buttons
$default-button-color: $dark-gray;
$secondary-button-color: $brand-color-2;

// typo
$default-font-size: rem-calc(16px);
$default-font-color: $dark-gray;
$link-color: $brand-color-1;
$link-color-darken: $brand-color-1-darken;

// form
$input-border: 1px solid #282d37;
$input-border-focus: 1px solid #282d37;
$input-border-focus-shadow: #282d37 0 0 3px 0;
$input-border-error: $alert-color;
$error-msg-color: $alert-color;

// progress bar
$progress-border-color: #979797;
$next-step-color: #cccccc;
$previous-step-color: $default-font-color;
$current-step-color: $brand-color-1;

// factor for mobile adjustments
$mobile-factor: 0.8;

