@import "../styles/customSettings";
@import "../styles/layout";

.ShoppingCartScreen {
  .products-in-shopping-cart {
    background-color: $light-gray-2;
    padding: rem-calc(80px) 0;
    @include breakpoint(medium down) {
      padding: rem-calc(40px) 0;
    }
  }
}
