@import "../../styles/customSettings";
.ProgressBar {
  max-width: rem-calc(1255px);
  margin: 0 auto;
  ul {
    position: relative;
    grid-template-columns: 72px 1fr 72px 1fr 72px 1fr 72px;
    @include breakpoint(small down) {
      grid-template-columns: calc(72px*#{$mobile-factor}) 1fr calc(72px*#{$mobile-factor}) 1fr calc(72px*#{$mobile-factor}) 1fr calc(72px*#{$mobile-factor});
    }
    display: inline-grid;
    width: 100%;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 72px);
      left: 50%;
      height: 0;
      border-top: 1px solid $progress-border-color;
      top: 36px;
      @include breakpoint(small down) {
        top: calc(36px*#{$mobile-factor});
      }
      transform: translateX(-50%);
      z-index: 0;
    }
  }
  li[class^="step"] {
    display: inline-block;
    position: relative;
    z-index: 1;
    img {
      display: inline-block;
      width: 72px;
      height: 72px;
      margin-bottom: rem-calc(15px);
      @include breakpoint(small down) {
        width: calc(72px*#{$mobile-factor});
        height: calc(72px*#{$mobile-factor});
      }
    }
    p {
      text-transform: uppercase;
      @include breakpoint(small down) {
        font-size: rem-calc(14px);
      }
      @include breakpoint(xsmall down) {
        font-size: rem-calc(12px);
      }
      @media screen and (max-width: 359px) {
        font-size: rem-calc(11px);
      }
    }
  }
  .step-3,
  .step-4 {
    text-align: right;
  }
  .step-2,
  .step-3 {
    @include breakpoint(xlarge down) {
      text-align: center;
    }
  }
  .step-content {
    display: inline-block;
    p {
      margin: 0 rem-calc(-20px);
    }
  }
  &.current-step-1 {
    .step-1 p {color: $current-step-color;}
    .step-2 p {color: $next-step-color;}
    .step-3 p {color: $next-step-color;}
    .step-4 p {color: $next-step-color;}
  }
  &.current-step-2 {
    .step-1 p {color: $previous-step-color;}
    .step-2 p {color: $current-step-color;}
    .step-3 p {color: $next-step-color;}
    .step-4 p {color: $next-step-color;}
  }
  &.current-step-3 {
    .step-1 p {color: $previous-step-color;}
    .step-2 p {color: $previous-step-color;}
    .step-3 p {color: $current-step-color;}
    .step-4 p {color: $next-step-color;}
  }
  &.current-step-4 {
    .step-1 p {color: $previous-step-color;}
    .step-2 p {color: $previous-step-color;}
    .step-3 p {color: $previous-step-color;}
    .step-4 p {color: $current-step-color;}
  }
}
