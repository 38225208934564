@import "customSettings";
@import "mixins";
@import "typo";
//@include foundation-global-styles;
body {
}

.layout-page-body {
  margin-top: $header-height;
  .layout-page-body-inner {
  }
}

// list styles
ol {
  &.how-to-list {
    list-style: none;
    margin: 0;
    li {
      min-height: rem-calc(24px);
      @include responsive-margin-bottom(rem-calc(18px));
      position: relative;
      padding-left: rem-calc(34px);
      span {
        display: inline-block;
        @include responsive-font-size($default-font-size*1.25, 0);
        line-height: 1.3;
        position: relative;
        top: -1px;
        @include breakpoint(small down) {
          position: relative;
          top: 2px;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: rem-calc(24px);
        display: block;
        float: left;
        overflow: hidden;
        margin: 0 rem-calc(10px) 0 0;
        width: rem-calc(24px);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
      }
      &:nth-child(1) {
        &:before {
          background-image: url("../assets/images/icons/step-1.png");
        }
      }
      &:nth-child(2) {
        &:before {
          background-image: url("../assets/images/icons/step-2.png");
        }
      }
      &:nth-child(3) {
        &:before {
          background-image: url("../assets/images/icons/step-3.png");
        }
      }
    }
  }

}


section {
  &.section-80 {
    margin-bottom: rem-calc(58px);
  }
  margin-bottom: rem-calc(80px);
  @include breakpoint(medium down) {
    margin-bottom: rem-calc(40px);
    &.section-80 {
      margin-bottom: rem-calc(30px);
    }
  }
  @include breakpoint(small down) {
    margin-bottom: rem-calc(40*$mobile-factor);
    &.section-80 {
      margin-bottom: rem-calc(30*$mobile-factor);
    }
  }
}
.grid-container { // grid adjustment
  @include breakpoint(xlarge down) {
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
  }
  .grid-container {
    @include breakpoint(xlarge down) {
      padding-left: rem-calc(10px);
      padding-right: rem-calc(10px);
    }
  }
}
/* helper */

.reset-margin-bottom {
  margin-bottom: 0;
}

.reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.color-primary {
  color: $brand-color-1;
}

.padding-l-r-20 {
  padding-left: rem-calc(20px) !important;
  padding-right: rem-calc(20px) !important;
}
.padding-l-r-10 {
  padding-left: rem-calc(10px) !important;
  padding-right: rem-calc(10px) !important;
}

[class*="Screen"].is-loading {
  min-height: calc(100vh - #{$header-height} - #{$footer-legal-height});
}

.hide {
  display: none;
}
.hide-visibility {
  visibility: hidden;
}
