@import "../../styles/customSettings";
.CartItem {
  .product-image {
    @include breakpoint(medium down) {
      margin-bottom: rem-calc($content-padding);
    }
    img {
      max-width: 220px;
      display: block;
      margin: 0 auto;
      width: 100%;
    }
  }
  .h4 {
    @include breakpoint(large up) {
      padding-top: rem-calc(23px);
    }
  }
}
