@import "../styles/customSettings";
@import "../styles/mixins";
.ConfirmScreen {
  min-height: calc(100vh - #{$header-height} - #{$footer-legal-height});
  .layout-page-body-inner {
    @include responsive-padding-top(20vh);
    img {
      width: rem-calc(72px);
      @include responsive-margin-bottom(rem-calc(32px));
    }
  }
}
