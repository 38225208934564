@import "../../styles/customSettings";
.layout-page-head {
  background-color: $header-bg-color;
  height: $header-height;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  .layout-page-head-inner {
    max-width: $screen-width;
    margin: 0 auto;
    height: 100%;
    position: relative;
    > a {
      position: absolute;
      top: 50%;
      display: inline-block;
      transform: translateY(-50%);
    }
    > a.brand-logo {
      display: flex;
      flex-direction: column;
      left: 20px;
      color: $default-font-color;
      &:hover,
      &:visited,
      &:focus {
        color: $default-font-color;
      }
      strong {
        padding-bottom: rem-calc(10px);
      }
      @include breakpoint(medium up) {
        flex-direction: row;
        align-items: center;
        strong {
          padding-right: rem-calc(20px);
          padding-bottom: 0;
        }
      }
      @include breakpoint(xxlarge up) {
        left: 10px;
      }
    }
    > a.shopping-cart {
      right: 10px;
      @include breakpoint(xlarge down) {
        right: 20px;
      }
      &:after {
        transition: opacity 400ms;
        content: '';
        position: absolute;
        bottom: 12px;
        right: 0;
        display: block;
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background-color: $brand-color-2;
        opacity: 0;
      }
      &.cart-indicator {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
