@import "../../styles/customSettings";
.Cart {
  .cart-separator {
    height: 0;
    border-top: 1px solid transparent;
    margin: 0 0 rem-calc(48px);
    @include breakpoint(medium down) {
      margin: rem-calc($content-padding) 0;
    }
  }
}
