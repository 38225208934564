@import "../styles/customSettings";
@import "../styles/mixins";

.HomeScreen {

  //@include spinner;

  @include two-fa-how-to;
  @include two-fa-how-to-video;
}
