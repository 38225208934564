@import "customSettings";
@include fontface($fonts);

body {
  color: $default-font-color;
  font-family: $default-font-family;
  font-weight: 400;
}

p, span, li, strong, label, a, input, input::placeholder {
  color: $default-font-color;
  font-family: $default-font-family;
  font-weight: 400;
  @include responsive-font-size($default-font-size, 0);
}
.two-fa-how-to p,
.two-fa-how-to li {
  @include responsive-font-size(rem-calc(18px), 0);
}
.choose-voucher h4 {
  @include responsive-font-size(rem-calc(18px), rem-calc(16px));
}
button, .button {
  color: $white;
  @include responsive-font-size($default-font-size, 0);
  span {
    color: $white;
  }
}

a:not(.button, .icons),
a:not(.button, .icons):visited,
a:not(.button, .icons):focus,
a:not(.button, .icons):active {
  color: $link-color;
  text-decoration: none;
  @include responsive-font-size($default-font-size, 0);
}
a:not(.button, .icons):hover {
  color: $link-color-darken;
}

strong {
  font-weight: 700;
  > a {
    font-weight: 700;
  }
}


input[type='text'],
input[type='email'] {
  @include responsive-font-size(rem-calc(16px), 0);
}

.form-error {
  @extend p;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $headline-font-family;
  font-weight: 500;
  a:not(.button, .icons),
  a:not(.button, .icons):visited,
  a:not(.button, .icons):focus,
  a:not(.button, .icons):active {
    font-weight: inherit !important;
    line-height: inherit !important;
    font-size: inherit !important;
  }
}

h1, .h1 {
  color: $white;
  line-height: 1.11;
  @include responsive-font-size($default-font-size*2.25, rem-calc(10px));
  .h1 {
    display: block;
    color: $black;
    font-weight: 700;
  }
}
h2, .h2 {
  color: $default-font-color;
  line-height: 1.11;
  @include responsive-font-size($default-font-size*2.25, rem-calc(30px));
  &.h5 {
    color: $white;
  }
}
h3, .h3 {
  @include responsive-font-size($default-font-size*1.875, rem-calc(16));
  &.h4 {
    @include responsive-font-size($default-font-size*1.5, rem-calc(16px));
    line-height: 1.17;
  }
}
h4, .h4 {
  @include responsive-font-size($default-font-size*1.5, rem-calc(16px));
  line-height: 1.17;
}
h5, .h5 {
  @include responsive-font-size($default-font-size*1.125, 0);
}
h6, .h6 {
  @include responsive-font-size($default-font-size, 0);
}

.icons {
  font-family: $icon-font-family;
  display: inline-block;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover {
    color: $default-font-color;
  }
}
.icon {
  width: 36px;
  height: 36px;
  font-size: 36px;

  &.xing:before {
    content: "\f12b";
  }
  &.youtube:before {
    content: "\f12c";
  }
  &.twitter:before {
    content: "\f129";
  }
  &.linkedin:before {
    content: "\f117";
  }
}

// helper
br {
  &.break-xxlarge,
  &.break-medium,
  &.break-large,
  &.break-small {
    display: none;
  }
  &.break-xxlarge {
    @include breakpoint(xxlarge up) {
      display: inline;
    }
  }
  &.break-large {
    @include breakpoint(large up) {
      display: inline;
    }
  }
  &.break-medium {
    @include breakpoint(medium up) {
      display: inline;
    }
  }
  &.break-small {
    @include breakpoint(small down) {
      display: inline;
    }
  }
}
.underlined {
  text-decoration: underline !important;
}
